import { size, H3, typography, Button, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${size.xl1}px auto ${size.xl7}px;
  padding: ${size.lg}px;

  ${mediaQueries.forTabletVerticalUp} {
    max-width: 532px;
    margin-top: ${size.xl4}px;
    padding: 0;
  }
`;

export const Header = styled(H3)`
  margin: ${size.xl1}px 0 ${size.md}px;
`;

export const Description = styled.p`
  ${typography.bodyText}
  margin-bottom: ${size.xl1}px;
`;

export const Image = styled.img`
  width: 96px;
  height: 96px;
`;
export const ScheduleFutureAppointmentButton = styled(Button)`
  width: 100%;
`;
