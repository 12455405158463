import { set, isWeekend } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const isUserWithinBookingWindow = (
  startHour: number,
  endingHour: number,
) => {
  const cstTimezone = 'America/Chicago';

  const now = new Date();

  const cstTime = utcToZonedTime(now, cstTimezone);

  const bookingWindowStart = set(cstTime, { hours: startHour, minutes: 0 });

  const bookingWindowEnd = set(cstTime, { hours: endingHour, minutes: 0 });

  const isWeekday = !isWeekend(cstTime);

  const isInBookingTimeWindow =
    cstTime >= bookingWindowStart && cstTime < bookingWindowEnd;

  const isWithinBookingWindow = isInBookingTimeWindow && isWeekday;

  return isWithinBookingWindow;
};
