import React, { useEffect } from 'react';
import analytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { RESULTS_ROOT } from 'utils/constants/urls';

import UnsuccessfulBookingIcon from '../../images/icons/unsuccessful-booking.svg';
import * as S from './AfterBusinessHoursContent.styles';

const redirectToInterstitialPage = () => {
  window.location.href = `${RESULTS_ROOT}/telehealth/sessions?program=sti-virtual-care-visit`;
};

function AfterBusinessHoursContent(): JSX.Element {
  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: 'On demand off hours',
      },
    });
  }, []);

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: 'After hours future appointment',
        program: 'sti-on-demand',
      },
    });
    redirectToInterstitialPage();
  };

  return (
    <S.Container>
      <S.Image src={UnsuccessfulBookingIcon} alt="" />
      <S.Header>After hours</S.Header>
      <S.Description>
        You have reached us outside our normal business hours, and clinicians
        are not currently available. Please click below to schedule a future
        appointment at a time that works best for&nbsp;you.
        <br /> <br />
        If you prefer to schedule an on-demand appointment, please return
        Monday-Friday, 7 a.m.-6 p.m. CST when our on-demand team is available.
        We will also be closed for all national holidays.
      </S.Description>
      <S.ScheduleFutureAppointmentButton
        appearance="primary"
        onClick={handleClick}
      >
        Schedule future appointment
      </S.ScheduleFutureAppointmentButton>
    </S.Container>
  );
}

export default AfterBusinessHoursContent;
