import AfterBusinessHoursContent from 'components/AfterBusinessHoursContent';
import { isUserWithinBookingWindow } from 'components/AfterBusinessHoursContent/utils/AfterBusinessHoursContent.helpers';
import Layout from 'components/Layout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isUndefined } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { RESULTS_ROOT } from 'utils/constants/urls';

const redirectToInterstitialPage = () => {
  window.location.href = `${RESULTS_ROOT}/telehealth/sessions?program=sti-on-demand`;
};

const OnDemandEntry = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { onDemandFlow } = useFlags();
  const isOnDemandFlowEnabled = onDemandFlow === true;

  // OpenLoop booking window is Mon-Fri 7am - 6pm CST
  const startHour = 7;
  const endHour = 18;
  const isWithinBookingWindow = isUserWithinBookingWindow(startHour, endHour);

  const isOnDemandFlowAccessible =
    isOnDemandFlowEnabled && isWithinBookingWindow;

  useEffect(() => {
    if (isOnDemandFlowAccessible) {
      redirectToInterstitialPage();
    }
  }, [isOnDemandFlowAccessible]);

  useEffect(() => {
    if (onDemandFlow === false || isUndefined(onDemandFlow)) {
      setIsLoading(false);
    }
  }, [onDemandFlow]);

  if (isLoading) return null;

  if (onDemandFlow === false || !isWithinBookingWindow) {
    return (
      <Layout>
        <AfterBusinessHoursContent />
      </Layout>
    );
  }

  return null;
};
export default OnDemandEntry;
